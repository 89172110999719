<template>
  <BaseCard
    title="datos del usuario"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <UserForm
      ref="user-form"
      v-model="user"
    />
  </BaseCard>
</template>

<script>
import UsersApi from '@/api/users-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import UserForm from '@/components/users/form/UserForm.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    UserForm,
    BaseCard,
  },
  data() {
    return {
      user: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      loggedUser: 'getUser',
    }),
    userForm() {
      return this.$refs['user-form']
    },
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    ...mapActions('auth', ['setUser']),
    async loadUser() {
      this.loading = true
      try {
        const response = await UsersApi.get(this.$route.params.id)
        this.user = response.data
        this.user.role = this.user.roles && this.user.roles.length > 0 ? this.user.roles[0] : null
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const success = await this.userForm.validateForm()
      if (!success) {
        return
      }

      this.loading = true
      try {
        delete this.user.roles
        delete this.user.comments

        if (this.userForm.isAvatarRemoved()) {
          await UsersApi.deleteAvatar(this.$route.params.id)
        }

        const response = await UsersApi.edit(this.$route.params.id, this.user, false)
        const updatedUser = response.data
        if (updatedUser.id === this.loggedUser.id) {
          this.setUser(response.data)
        }

        this.$toast('Recurso actualizado con éxito.')
        await this.$router.go(-1)
      } catch (error) {
        this.handleError(error)
      }
      this.loading = false
    },
    handleError(error) {
      if (error.response.data?.email) {
        this.userForm.handleUniqueEmailError(error.response.data?.email)
      } else {
        console.error(error)
        this.$toast.error('Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo mas tarde.')
      }
    },
  },
}
</script>

<style lang="scss">
</style>
